import React, { useEffect, useState } from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import useWindowSize from '../hooks/useWindowSize'
import Pattern from '../images/tuya-pattern-menu.inline.svg'

// Styles
import '../styles/pages/reservation.scss'

const ReservationPage = ({ location }) => {
  return (
    <Layout className="reservation-page" pathname={location.pathname} hasLightBg simpleHeader>
      <SEO title="Reservations">
        {/* <script src="https://www.sevenrooms.com/widget/embed.js" async="true" type="text/javascript" />
        <script type="text/javascript">
          {`
          SevenroomsWidget.init({
            venueId: "tuyamykonos",
            triggerId: "sr-res-root", // id of the dom element that will trigger this widget
            type: "reservations", // either 'reservations' or 'waitlist' or 'events'
            styleButton: true, // true if you are using the SevenRooms button
            clientToken: "" //(Optional) Pass the api generated clientTokenId here
          })

      `}
        </script> */}
      </SEO>
      <section className="hero-section flex items-end px-6 pb-7 md:pb-16 md:px-20 lg:px-32 ">
        <div className="2xl:max-w-screen-xl 2xl:mx-auto w-full">
          <h1 className="text-tuya-zeta">Reservation</h1>
        </div>
      </section>
      <section className="main-section">
        <div className="wrapper flex items-center justify-center pt-32">
          <div style={{ width: '230px' }} className="bg-tuya-gamma text-white">
            <div id="sr-res-root" className="sr-md sr-dark sr-#274d65b">
              Click for reservations!
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ReservationPage
